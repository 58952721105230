import { Button, Grid } from '@mui/material'
import { useGetNews } from 'hooks/api/useGetNews'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { InfoCenterHeader } from './InfoCenterHeader'
import { NewsCard } from './NewsCard'

export const NewsPage = () => {
  const useGetNewsQuery = useGetNews()
  const sortedNews = useGetNewsQuery.data?.sort((a, b) => {
    const dateA = new Date(a.date ?? 0)
    const dateB = new Date(b.date ?? 0)
    return dateB.getTime() - dateA.getTime()
  })

  if (useGetNewsQuery.isPending) {
    return (
      <InfoCenterHeader currentTab={'/InfoCenter/NewsPage'}>
        <ActivityIndicator />
      </InfoCenterHeader>
    )
  }

  return (
    <InfoCenterHeader currentTab={'/InfoCenter/NewsPage'}>
      <Grid container spacing={2}>
        {sortedNews?.map((n) => (
          <Grid item key={n.url}>
            <NewsCard news={n} />
          </Grid>
        ))}
      </Grid>
      <Button
        variant="contained"
        component="a"
        href={'https://www.jjventures.com/news/jj-stories/'}
        target="_blank"
        rel="noopener noreferrer"
      >
        View More
      </Button>
    </InfoCenterHeader>
  )
}
