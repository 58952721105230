import { useApiQuery } from 'hooks/useApiQuery'

export const useGetSweepstakeByIdWithDrawingInfo = ({
  sweepstakeId,
}: {
  sweepstakeId?: number
}) =>
  useApiQuery({
    path: '/le-connect/contests/{id}/drawing-info',
    method: 'get',
    queryKey: ['/sweepstakes', sweepstakeId],
    queryArgs: { id: sweepstakeId },
    enabled: sweepstakeId != null,
  })
