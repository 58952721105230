import { useGetNewsletters } from 'hooks/api/useGetNewsletters'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { InfoCenterHeader } from './InfoCenterHeader'
import { NewsletterCard } from './NewsletterCard'

export const NewslettersPage = () => {
  const useGetNewslettersQuery = useGetNewsletters()
  const filteredAndSortedNewsletters = useGetNewslettersQuery.data
    ?.filter((newsletter) => newsletter.active)
    .sort((a, b) => {
      const dateA = new Date(a.createdOn ?? 0)
      const dateB = new Date(b.createdOn ?? 0)
      return dateB.getTime() - dateA.getTime()
    })

  if (useGetNewslettersQuery.isPending) {
    return (
      <InfoCenterHeader currentTab={'/InfoCenter/NewslettersPage'}>
        <ActivityIndicator />
      </InfoCenterHeader>
    )
  }

  return (
    <InfoCenterHeader currentTab={'/InfoCenter/NewslettersPage'}>
      {filteredAndSortedNewsletters?.map((n) => (
        <NewsletterCard key={n.id} newsletter={n} />
      ))}
    </InfoCenterHeader>
  )
}
