import { Content } from './Content'
import LogoImage from 'assets/jjConnectLogo.svg'
import { useLayout } from 'hooks/useLayout'
import { type NavBarRouteDef } from 'src/types/nav'
import { colors } from 'components/ThemeProvider'
import { APP_BAR_HEIGHT } from 'components/AppBar/AppBar'
import { Box, Divider, Drawer, SvgIcon } from '@mui/material'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import ComplianceIcon from 'assets/ComplianceIcon.svg?react'
import PaymentIcon from 'assets/PaymentIcon.svg?react'
import ReportsIcon from 'assets/ReportsIcon.svg?react'
import LocationsIcon from 'assets/LocationsIcon.svg?react'
import CommunicationsIcon from 'assets/CommunicationsIcon.svg?react'
import DashboardIcon from 'assets/dashboard.svg?react'
import PlayerPaybackIcon from 'assets/PlayerPaybackIcon.svg?react'
import ContactUsIcon from 'assets/ContactUsIcon.svg?react'
import InfoCenterIcon from 'assets/InfoCenterIcon.svg?react'
import { DeployVersion } from 'components/DeployVersion'
import { useSideNavCollapseStore } from 'stores/useSideNavCollapseStore'
import React from 'react'
import { useNavBar } from 'hooks/useNavBar'

export const NavBarRoutes: Record<string, NavBarRouteDef> = {
  Dashboard: {
    path: '/Home',
    icon: (
      <SvgIcon
        sx={{
          color: colors.text.primary,
          transform: 'translate(0, -2px)',
        }}
      >
        <DashboardIcon />
      </SvgIcon>
    ),
  },
  FlexPay: {
    path: '/FlexPay',
    icon: (
      <SvgIcon sx={{ color: colors.text.primary }}>
        <PaymentIcon />
      </SvgIcon>
    ),
  },
  Compliance: {
    path: '/Compliance',
    icon: (
      <SvgIcon sx={{ color: colors.text.primary }}>
        <ComplianceIcon />
      </SvgIcon>
    ),
    secondaryIcon: (
      <SvgIcon
        sx={{
          color: colors.error.main,
        }}
      >
        <ErrorOutlineOutlinedIcon />
      </SvgIcon>
    ),
  },
  'Gaming Reports': {
    path: '/GamingReports',
    icon: (
      <SvgIcon sx={{ color: colors.text.primary }}>
        <ReportsIcon />
      </SvgIcon>
    ),
  },
  'Player PAYBACK\u00AE': {
    path: '/PlayerPayback',
    icon: (
      <SvgIcon
        sx={{ color: colors.text.primary, transform: 'translate(0, -2px)' }}
      >
        <PlayerPaybackIcon />
      </SvgIcon>
    ),
    childrenRoutes: [
      {
        title: 'Patron Check Ins',
        path: '/PatronCheckIns',
        icon: null,
        associatedPageUrls: ['/PatronCheckIns'],
      },
      {
        title: 'Rewards',
        path: '/Rewards',
        icon: null,
        associatedPageUrls: ['/Rewards'],
      },
      {
        title: 'Specials & Events',
        path: '/Specials&Events',
        icon: null,
        associatedPageUrls: ['/Specials&Events'],
      },
      {
        title: 'J&J Sweepstakes',
        path: '/Sweepstakes',
        icon: null,
        associatedPageUrls: ['/Sweepstakes'],
      },
      {
        title: 'Player PAYBACK\u00AE Reports',
        path: '/PlayerPaybackReports/PatronActivity',
        icon: null,
        associatedPageUrls: ['/PlayerPaybackReports/PatronActivity'],
      },
    ],
  },
  Locations: {
    path: '/Locations',
    icon: (
      <SvgIcon
        sx={{ color: colors.text.primary, transform: 'translate(0, -2px)' }}
      >
        <LocationsIcon />
      </SvgIcon>
    ),
  },
  'J&J Connect Users': {
    path: '/LicensedEstablishmentUsers',
    icon: (
      <SvgIcon
        sx={{ color: colors.text.primary, transform: 'translate(2px, 2px)' }}
      >
        <CommunicationsIcon />
      </SvgIcon>
    ),
  },
  'Info Center': {
    path: '/InfoCenter/Newsletters',
    icon: (
      <SvgIcon sx={{ color: colors.text.primary }}>
        <InfoCenterIcon />
      </SvgIcon>
    ),
  },
  'Contact Us': {
    path: '/ContactUs',
    icon: (
      <SvgIcon sx={{ color: colors.text.primary }}>
        <ContactUsIcon />
      </SvgIcon>
    ),
  },
}

export const NAVIGATION_BAR_WIDTH = 261

export const NavigationBar = () => {
  const isOpen = useNavBar((state: { isOpen: boolean }) => state.isOpen)
  const close = useNavBar((state: { close: () => void }) => state.close)
  const { isMobile } = useLayout()

  const setActiveTab = useSideNavCollapseStore((state) => state.setActiveTab)

  React.useEffect(() => {
    setActiveTab(location.pathname)
  }, [location.pathname, setActiveTab])

  if (isMobile) {
    return (
      <Drawer
        variant="temporary"
        PaperProps={{
          sx: { top: 0 },
        }}
        sx={{
          display: {
            xs: 'block',
            sm: 'block',
            md: 'block',
            lg: 'none',
          },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: isMobile
              ? `${NAVIGATION_BAR_WIDTH * 1.3}px`
              : `${NAVIGATION_BAR_WIDTH}px`,
          },
        }}
        open={isOpen}
        onClose={close}
        data-testid="mobile-navigation-bar"
      >
        <Box m={1} sx={{ ml: 2 }}>
          <img src={LogoImage} width={50} alt="LogoImage" />
        </Box>
        <Divider />
        <Content />
      </Drawer>
    )
  }

  return (
    <Drawer
      variant="permanent"
      PaperProps={{
        sx: { top: `${APP_BAR_HEIGHT}px` },
      }}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: `${NAVIGATION_BAR_WIDTH}px`,
          height: `calc(100% - ${APP_BAR_HEIGHT}px)`,
          overflowX: 'hidden',
        },
      }}
      open={true}
      data-testid="desktop-navigation-bar"
    >
      <Content />
      <DeployVersion />
    </Drawer>
  )
}
