import { Page } from 'components/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { InfoCenterPageTabs } from './InfoCenterPageTabs'

export const InfoCenterHeader = ({
  currentTab,
  children,
}: {
  currentTab: string
  children?: React.ReactNode
}) => {
  return (
    <Page
      header={
        <PageHeader
          title="Info Center"
          isSecondary={true}
          tabs={<InfoCenterPageTabs currentTab={currentTab} />}
        />
      }
    >
      {children}
    </Page>
  )
}
